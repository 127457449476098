<template>
  <telia-footer hide-top>
    <section slot="social">
      <telia-style-link
        v-for="social in socials"
        :key="social.link"
        variant="navigation"
        dark-background
        disable-visited
        ensure-click-area
      >
        <a :href="social.link" :aria-label="t(social.label)">
          <telia-icon :name="social.icon"></telia-icon>
        </a>
      </telia-style-link>
    </section>
    <section slot="legal">
      <telia-footer-legal
        :items="legalItemJson"
        @click="onLegalClick"
        copyright="© Telia Sverige AB"
      >
        <div slot="contacts">
          <telia-list list-tag="ul">
            <li>556430-0142</li>
            <li>Box 50077</li>
            <li>973 22 Luleå</li>
            <li>Säte: Stockholm</li>
          </telia-list>
        </div>
      </telia-footer-legal>
    </section>
  </telia-footer>
</template>

<script>
import { translateMixin, translateSetup } from "./locales";
export default {
  name: "b2b-footer",
  mixins: [translateMixin],
  created() {
    translateSetup();
  },
  methods: {
    getLinkHash(element) {
      if (element?.tagName?.toUpperCase() !== "A") {
        return false;
      }
      if (element?.hash) {
        return element.hash;
      }

      try {
        return new URL(element.href).hash;
      } catch {
        return "";
      }
    },
    onLegalClick(event) {
      const isCookieConsentClick = event
        .composedPath()
        .some((element) => this.getLinkHash(element) === "#cookie-consent");

      if (isCookieConsentClick) {
        event.preventDefault();
        this.openConsentPreferences();
      }
    },
    openConsentPreferences() {
      window.websiteCookieConsent?.openConsentPreferences?.();
    },
  },

  computed: {
    legalItemJson() {
      return JSON.stringify([
        {
          href: "/foretag/om/integritetspolicy",
          name: this.t("PRIVACY_POLICY"),
        },
        {
          href: "#cookie-consent",
          name: this.t("COOKIE_CONSENT"),
        },
        {
          href: "/foretag/om/tekniska-krav",
          name: this.t("ABOUT_SITE"),
        },
      ]);
    },
    socials() {
      return [
        {
          link: "https://www.linkedin.com/company/teliacompany/",
          label: "LINK_TO_LINKEDIN",
          icon: "linkedin",
        },
        {
          link: "https://facebook.com/telia",
          label: "LINK_TO_FACEBOOK",
          icon: "facebook",
        },
        {
          link: "https://twitter.com/TeliaSverige",
          label: "LINK_TO_TWITTER",
          icon: "twitter",
        },
        {
          link: "https://youtube.com/teliaforetag",
          label: "LINK_TO_YOUTUBE",
          icon: "youtube",
        },
      ];
    },
  },
};
</script>
