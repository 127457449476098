import singleSpaHtml from "single-spa-html";
import "./main.automount";

const vueLifecycles = singleSpaHtml({
  template: "<b2b-footer></b2b-footer>",
});

export const bootstrap = vueLifecycles.bootstrap;
export const mount = vueLifecycles.mount;
export const unmount = vueLifecycles.unmount;
