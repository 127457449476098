import Vue from "vue";
import B2bFooter from "./b2b-footer.vue";
import wrap from "@telia/vue-web-component-wrapper-ie11";

Vue.config.ignoredElements = [/telia-\w*/, /mybd-\w*/, /b2[bcx]-/];

export function defineCustomElements() {
  const CustomElement = wrap(Vue, B2bFooter);

  window.customElements.define("b2b-footer", CustomElement);
}

defineCustomElements();
