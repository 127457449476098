var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("telia-footer", { attrs: { "hide-top": "" } }, [
    _c(
      "section",
      { attrs: { slot: "social" }, slot: "social" },
      _vm._l(_vm.socials, function (social) {
        return _c(
          "telia-style-link",
          {
            key: social.link,
            attrs: {
              variant: "navigation",
              "dark-background": "",
              "disable-visited": "",
              "ensure-click-area": "",
            },
          },
          [
            _c(
              "a",
              {
                attrs: { href: social.link, "aria-label": _vm.t(social.label) },
              },
              [_c("telia-icon", { attrs: { name: social.icon } })],
              1
            ),
          ]
        )
      }),
      1
    ),
    _c(
      "section",
      { attrs: { slot: "legal" }, slot: "legal" },
      [
        _c(
          "telia-footer-legal",
          {
            attrs: {
              items: _vm.legalItemJson,
              copyright: "© Telia Sverige AB",
            },
            on: { click: _vm.onLegalClick },
          },
          [
            _c(
              "div",
              { attrs: { slot: "contacts" }, slot: "contacts" },
              [
                _c("telia-list", { attrs: { "list-tag": "ul" } }, [
                  _c("li", [_vm._v("556430-0142")]),
                  _c("li", [_vm._v("Box 50077")]),
                  _c("li", [_vm._v("973 22 Luleå")]),
                  _c("li", [_vm._v("Säte: Stockholm")]),
                ]),
              ],
              1
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }